





















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {clientModule, invoiceModule, timeEntryModule} from '@/store/modules';
import {
  AlertMessage,
  ClientResource,
  SelectItem,
  TimeEntryResource,
} from '@/interfaces';
import {mdiCalendar} from '@mdi/js';
import {actionApi} from '@/api';
import {ResourceType} from '@/enums';
import {endOfMonth, format, startOfMonth, subMonths} from 'date-fns';
import {InputValidationRules} from 'vuetify';

@Component({
  components: {},
})
export default class InvoiceCreateDialog extends Vue {
  alert: AlertMessage = {type: '', message: ''};
  clientId: string | null = null;
  dateMenuStart = false;
  dateMenuEnd = false;
  iconCalendar = mdiCalendar;
  invoiceType = 'blank';
  valid = '';
  saving = false;
  rulesRequired: InputValidationRules = [v => !!v || 'This field is required'];
  today = new Date();
  lastMonth = subMonths(this.today, 1);
  dateStart: string = format(startOfMonth(this.lastMonth), 'yyyy-MM-dd');
  dateEnd: string = format(endOfMonth(this.lastMonth), 'yyyy-MM-dd');

  get clients(): ClientResource[] {
    return clientModule.list;
  }

  get client(): ClientResource | null {
    if (!this.clientId) {
      return null;
    }
    const client = clientModule.find(this.clientId);
    if (!client) {
      return null;
    }
    return client;
  }

  get clientSelectItems(): SelectItem[] {
    return this.clients.map(item => ({
      text: item.attributes.name,
      value: item.id,
    }));
  }

  get listing(): boolean {
    return clientModule.listing;
  }

  get timeEntriesLoading(): boolean {
    return timeEntryModule.listing;
  }

  get timeEntries(): TimeEntryResource[] {
    return timeEntryModule.list;
  }

  async created() {
    await clientModule.loadMulti();
  }

  cancel(): void {
    this.$emit('close');
  }

  async save() {
    this.saving = true;
    if (!this.clientId) {
      throw Error('Client ID is required');
    }
    try {
      let invoiceId: string;
      if (this.invoiceType === 'time-entries') {
        const action = await actionApi.post({
          type: ResourceType.Action,
          attributes: {
            action: 'create-invoice-from-time-entries',
            clientId: this.clientId,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
          },
        });
        invoiceId = action.id;
      } else {
        const newInvoice = await invoiceModule.loadById('new');
        newInvoice.attributes.clientId = parseInt(this.clientId);
        const invoice = await invoiceModule.save('new');
        invoiceId = invoice.id;
      }
      this.alert = {
        type: 'success',
        message: 'Invoice created, redirecting you shortly.',
      };
      setTimeout(
        async () => await this.$router.push(`/invoices/${invoiceId}`),
        2000
      );
      this.saving = false;
    } catch (err) {
      this.saving = false;
      //this.alert = err.message
      this.alert = {
        type: 'error',
        message: 'Error creating invoice.' + err.message,
      };
      console.error(err);
    }
  }

  async update(): Promise<void> {
    this.dateMenuStart = false;
    this.dateMenuEnd = false;
    if (!this.clientId || !this.dateStart || !this.dateEnd) {
      return;
    }
    await timeEntryModule.loadMulti({
      filters: {
        clientId: this.clientId,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      },
    });
  }
}

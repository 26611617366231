var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('PageTitle',[_vm._v("Invoices")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.role === _vm.roles.Staff ? _vm.headersStaff : _vm.headersClient,"items":_vm.invoices,"items-per-page":25,"search":_vm.search,"loading":_vm.loading,"sort-by":['attributes.date'],"sort-desc":[true],"item-key":"id"},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.attributes.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.shortDate(item.attributes.date)))])]}},{key:"item.attributes.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.shortDate(item.attributes.dueDate))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getTypeIcon(item.attributes.type)))])]}},{key:"item.attributes.subtotal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.numberFormat(item.attributes.subtotal / 100))+" "),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(item.attributes.currency))])])]}},{key:"item.attributes.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(_vm._s(_vm.numberFormat(item.attributes.total / 100))+" "),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(item.attributes.currency))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.getPdf(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.downloadIcon))])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.role === _vm.roles.Staff)?_c('v-btn',{attrs:{"fab":"","bottom":"","right":"","fixed":"","color":"primary"},on:{"click":_vm.showInvoiceCreateDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.newInvoiceIcon))])],1):_vm._e(),(_vm.invoiceCreateDialogShow)?_c('invoice-create-dialog',{on:{"close":function (args) { return _vm.closeInvoiceCreateDialog(args); }}}):_vm._e(),_c('v-snackbar',{attrs:{"bottom":""},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
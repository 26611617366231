













































































import Vue from 'vue';
import {
  mdiMagnify,
  mdiHammer,
  mdiPackageVariantClosed,
  mdiDownload,
  mdiPlus,
} from '@mdi/js';
import InvoiceCreateDialog from '../components/InvoiceCreateDialog.vue';
import Component from 'vue-class-component';
import {authModule, invoiceModule} from '@/store/modules';
import {invoiceApi} from '@/api';
import {InvoiceResource, Snackbar} from '@/interfaces';
import {InvoiceType, Role} from '@/enums';
import {shortDate, numberFormat} from '@/filters';
import PageTitle from '@/components/PageTitle.vue';
import {downloadFile} from '@/utility';

@Component({
  components: {PageTitle, InvoiceCreateDialog},
})
export default class Invoices extends Vue {
  private search = '';
  private selected = [];
  private shortDate = shortDate;
  private numberFormat = numberFormat;
  private newInvoiceIcon = mdiPlus;
  private searchIcon = mdiMagnify;
  private downloadIcon = mdiDownload;
  private headersClient = [
    {
      text: 'Invoice No.',
      align: 'left',
      sortable: true,
      name: 'id',
      value: 'id',
    },
    {text: 'Date', value: 'attributes.date'},
    {text: 'Due Date', value: 'attributes.dueDate', class: 'no-wrap'},
    {align: 'right', text: 'Subtotal', value: 'attributes.subtotal'},
    {align: 'right', text: 'Total', value: 'attributes.total'},

    {align: 'center', text: 'Actions', value: 'action'},
  ];
  private headersStaff = [
    {align: 'center', text: 'Type', value: 'type'},
    {
      text: 'Invoice No.',
      align: 'left',
      sortable: true,
      name: 'id',
      value: 'id',
    },
    {text: 'Client', value: 'attributes.client.name'},
    {text: 'Date', value: 'attributes.date'},
    {text: 'Due Date', value: 'attributes.dueDate', class: 'no-wrap'},
    {align: 'right', text: 'Subtotal', value: 'attributes.subtotal'},
    {align: 'right', text: 'Total', value: 'attributes.total'},

    {align: 'center', text: 'Actions', value: 'action'},
  ];
  private invoiceCreateDialogShow = false;
  private loading = true;
  private roles = Role;
  private snackbar: Snackbar = {visible: false, message: ''};

  get role() {
    return authModule.role;
  }
  get clientId() {
    return authModule.clientId;
  }
  get invoices() {
    return invoiceModule.list;
  }

  async created() {
    this.loading = true;
    await invoiceModule.loadMulti({
      filters: {
        clientId: this.role === Role.Staff ? '-1' : String(this.clientId),
      },
    });
    console.log(this.invoices);
    this.loading = false;
  }

  getTypeIcon(type: string) {
    console.log(type);
    if (type === InvoiceType.Product) {
      return mdiPackageVariantClosed;
    } else {
      return mdiHammer;
    }
  }

  view(row: InvoiceResource) {
    this.$router.push({path: `/invoices/${row.id}`});
  }

  async getPdf(invoiceId: string) {
    const invoice = await invoiceApi.getRaw(invoiceId, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    });
    console.log(invoice);
    // const file = new File(invoice, 'invoice.pdf');
    // console.log(file);
    downloadFile(invoice, `Invoice ${invoiceId} from John Clark.pdf`);
  }

  showInvoiceCreateDialog() {
    // if (typeof id === 'undefined') {
    //   id = -1;
    // }
    // this.projectId = id;
    this.invoiceCreateDialogShow = true;
  }

  closeInvoiceCreateDialog(message?: string) {
    this.invoiceCreateDialogShow = false;
    if (message) {
      this.snackbar = {visible: true, message: message};
    }
  }
}
